import React from 'react';
import Img1 from '../assets/img1.webp'
import Img2 from '../assets/img2.webp'
import Img33 from '../assets/img33.webp'


const Slider = () => {
  return (
    <section className="slider">
      <div className="container">
        <a name="slider"></a>
        <div className="slider1">
          <img className="sl1" src={Img1} alt="Image 1" />
          <img className="sl2" src={Img2} alt="Image 2" />
          <img className="sl3" src={Img33} alt="Image 3" />
        </div>
        <div className="slider2">
          <img className="sl1" src={Img2} alt="Image 1" />
          <img className="sl2" src={Img33} alt="Image 2" />
          <img className="sl3" src={Img1} alt="Image 3" />
        </div>
      </div>
    </section>
  );
};

export default Slider;
