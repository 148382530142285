import React from 'react';
import Logo from '../assets/Logo.webp'


const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__title">
          <img src={Logo} alt="Logo" />
          <a className='a__grid pp0' href="https://offthegridlab.com/"><h2>Made by OffTheGrid</h2></a>
        </div>
        <div className="footer__sub">
          <p className="footer__p pp1">©2023 Chef Alex</p>
          <p className="footer__p pp2">Privacy Policy</p>
          <p className="footer__p pp3">Terms of use</p>
        </div>
        <div className="footer__container">
          <img src={Logo} alt="Logo" />
          <a className='a__grid pp0' href="https://offthegridlab.com/"><h2>Made by OffTheGrid</h2></a>
          <p className="footer__p pp1">©2023 Chef Alex</p>
          <p className="footer__p pp2">Privacy Policy</p>
          <p className="footer__p pp3">Terms of use</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
