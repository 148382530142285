import React from 'react';
import Button from '../assets/btnF.svg'
import Img1 from '../assets/imgF.webp'
import Img2 from '../assets/imgF1.webp'




const Popup = ({ showPopup, setShowPopup }) => {
  if (!showPopup) return null;

  return (
    <div className='popup'>
      <div className='popup-content'>
        <h2>THANK YOU!</h2>
        <p className='pop__p'>Your data has been sent successfully, expect a call from our managers.</p>
        <div className="pop__up">
        <img className='pop__ii' src={Img1} alt="" />
        <img className='pop__i' src={Img2} alt="" />
        <img className='pop__img' onClick={() => setShowPopup(false)} src={Button} alt="" />
        </div>
        

        <button className='close-btn' onClick={() => setShowPopup(false)}>GO BACK TO THE MAIN PAGE</button>
      </div>
    </div>
  );
};

export default Popup;
