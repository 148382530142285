import React from 'react';
import Img1 from '../assets/info1.webp'
import Img2 from '../assets/info11.webp'
import Img3 from '../assets/info2.webp'
import Img4 from '../assets/info22.webp'
import Img5 from '../assets/unfo3.webp'
import Img6 from '../assets/info33.webp'
import Ellipse from '../assets/Ellipse.svg'
import Ellipse1 from '../assets/Ellipse1.svg'




const ServiceItem = ({ title, img1, img2, number, description }) => (
  <div className="section__item">
    <div className="service__form">
      <div className="service__container">
        <div className="top-left">{title}</div>
      </div>
      <img className="section__img" src={img1} alt="" />
      <div className="service__container1">
        <div className="top-left">{title}</div>
      </div>
      <div className="service__el">
      <img className="section__images" src={img2} alt="" />
      <div className="service__btn">
        <a href='#contact'>
      <img className="service__i" src={Ellipse} alt="" />
     <img className="service__i" src={Ellipse1} alt="" />
     </a>
      </div>
    </div>
    <div className="service__el1">
      <img className="section__images" src={img2} alt="" />
      <div className="service__btn">
      <a href='#contact'>
     <img className="service__i" src={Ellipse} alt="" />
      <img className="service__i" src={Ellipse1} alt="" />
      </a>
      </div>
    </div>
    </div>
    <div className="service__suptitle">
      <h1 className="s__t">{number}</h1>   
      <p className="service__sup">{description}</p>
    </div>
  </div>
);

const Service = () => (
  <section className="service">
    <a name="service"></a>
    <div className="container">
      <div className="service__title">
        <h1 className="servise__h">OUR SERVICE</h1>
        <p className="servise__p">I suggest that you immediately familiarizeyourself with our services</p>
      </div>
    </div>
    <ServiceItem
      title="CHEF AT THE EVENT"
      img1={Img1}
      img2={Img2}
      number="01"
      description="Events We Cater For, Weddings, Birthdays,Anniversaries, Corporate Events, Business Lunches, BBQs, Hog Roasts, CelebrationsShows & Events, All Other Events"
    />
    <ServiceItem
      title="DELIVERY"
      img1={Img3}
      img2={Img4}
      number="02"
      description="Whether you’re looking to have individually packaged or hot lunch for your office- or a holiday meal drop off, we would love to hear from you!"
    />
    <ServiceItem
      title="MIAMI BBQ PARTY"
      img1={Img5}
      img2={Img6}
      number="03"
      description="Regular BBQs on Sundays! Come and taste what we are cooking for you, your friends, and the kids at our signature BBQ party! New acquaintances, entertainment for the kids and premium food—the recipe for a successful Sunday!"
   />
  </section>
);

export default Service;
