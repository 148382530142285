import React from 'react';
import Inst from '../assets/Inst.svg'
import Phone from '../assets/Phone.svg'
import Telegram from '../assets/Telegram.svg'
import WhatsApp from '../assets/WhatsApp.svg'

const Info = () => {
  return (
    <section className="info">
      <div className="container">
        <div className="info__container">
          <div className="info__title">
            <h1 className="info__t">A Passion for Gourmet Food</h1>
          </div>
          <div className="info__block">
            <div className="info__logo">
              <img src={Inst} alt="" />
              <img src={Phone} alt="" />
              <img src={Telegram} alt="" />
              <img src={WhatsApp} alt="" />
            </div>
            <div className="info__p">
              <p>Our chef has a divine gift for the culinary arts. Wherever possible, thanks to his gift, and hence with an unparalleled culinary flair and a deep-rooted passion for gourmet food, he uses whatever is at hand.</p>
              <p className="p1">Our service team will turn your special day into an unforgettable experience.</p>
            </div>
          </div>
          <a name="info"></a>
        </div>
        <div className="link">
          <div className="link1">
          <iframe width="350" height="255" src="https://www.youtube.com/embed/W7RNflrebWs?si=F35h6IaCdOtyfIVZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>          </div>
<div className="link2">
<iframe width="1330" height="370" src="https://www.youtube.com/embed/W7RNflrebWs?si=F35h6IaCdOtyfIVZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>
        </div>
      </div>
    </section>
  );
};

export default Info;
