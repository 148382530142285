import React from 'react';

const Title = () => {
  return (
    <section className="title">
      <div className="container">
        <div className="titles">
          <div className="title__block bl1">
            <h1 className="title_h1">PERFECT</h1>
            <h2 className="title__h2">IN SOUTH FLORIDA</h2>
          </div>
          <div className="title__block bl2">
          <a href='#contact'><h2 className="title__h2 hh2">BOOK CONTACTS</h2></a>
            <h1 className="title_h1 hh1">CATERING</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Title;
