import './App.css';
import Header from './components/header';
import Slider from './components/slider';
import Title from './components/title';
import Info from './components/info';
import Service from './components/service';
import Gallery from './components/gallery';
import Contact from './components/contact';
import Uses from './components/us';
import Footer from './components/footer';



function App() {
  return (
  <>
  <Header />
  <Slider />
  <Title />
  <Info />
  <Service />
  <Gallery />
  <Contact />
  <Uses />
  <Footer />
  </>
  );
}

export default App;
