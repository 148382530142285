import React, { useState, useEffect } from 'react';
import Inst from '../assets/Inst.svg'
import Phone from '../assets/Phone.svg'
import Telegram from '../assets/Telegram.svg'
import WhatsApp from '../assets/WhatsApp.svg'
import WhatsApp1 from '../assets/sApp.svg'
import Inst1 from '../assets/Inst11.svg'
import Phone1 from '../assets/Phone11.svg'
import Telegram1 from '../assets/Telegram11.svg'
import Us from '../assets/us.jpg'


// ...
// ...

const Uses = () => {
  const [instSrc, setInstSrc] = useState(Inst);
  const [phoneSrc, setPhoneSrc] = useState(Phone);
  const [telegramSrc, setTelegramSrc] = useState(Telegram);
  const [whatsAppSrc, setWhatsAppSrc] = useState(WhatsApp);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className="us">
      <div className="container">
        <div className="us__us">
          <div className="us__number">
            <p className="us__p">+1 (786) 520 7441</p>
            <p className="us__p">+1 (754) 202 6868</p>
            <p className="us__p ll">mbbqparty@gmail.com</p>
          </div>
          <div className="us__icon">
            <div className="us__item">
            <a href="https://instagram.com/chefalex.pro?igshid=MWI5bDN6aHRqa3F6Yg=="><img className="us__img" src={instSrc} alt="" onMouseOver={() => windowWidth > 1000 && setInstSrc(Inst1)} onMouseOut={() => windowWidth > 1000 && setInstSrc(Inst)} /></a>
            <a href="tel:+17865207441"><img className="us__img" src={phoneSrc} alt="" onMouseOver={() => windowWidth > 1000 && setPhoneSrc(Phone1)} onMouseOut={() => windowWidth > 1000 && setPhoneSrc(Phone)} /></a>
            </div>
            <div className="us__item ll">
            <img className="us__img" src={telegramSrc} alt="" onMouseOver={() => windowWidth > 1000 && setTelegramSrc(Telegram1)} onMouseOut={() => windowWidth > 1000 && setTelegramSrc(Telegram)} />
            <a href="https://wa.me/17542026868"><img className="us__img" src={whatsAppSrc} alt="" onMouseOver={() => windowWidth > 1000 && setWhatsAppSrc(WhatsApp1)} onMouseOut={() => windowWidth > 1000 && setWhatsAppSrc(WhatsApp)} /></a>
            </div>
          </div>
          <div className="us__image">
            <img className='us__i' src={Us} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Uses;
