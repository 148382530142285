import React, { useState } from 'react';
import Logo from '../assets/Logo.svg'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [line1, setLine1] = useState(false);
  const [line2, setLine2] = useState(false);
  const [line3, setLine3] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setLine1(!line1);
    setLine2(!line2);
    setLine3(!line3);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header__container">
          <div className="header__logo">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="header__menu">
            <button className={`mobile-nav-button ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className={`mobile-nav-button__line ${line1 ? 'mobile-nav-button__line--1' : ''}`}></div>
              <div className={`mobile-nav-button__line ${line2 ? 'mobile-nav-button__line--2' : ''}`}></div>
              <div className={`mobile-nav-button__line ls ${line3 ? 'mobile-nav-button__line--3' : ''}`}></div>
            </button>

            {isOpen && (
              <nav className="mobile-menu">
                  <img className='lg' src={Logo} alt="Logo" />

                <ul>
                  <li><a href="#slider" onClick={toggleMenu}>MAIN</a></li>
                  <li><a href="#service" onClick={toggleMenu}>SERVICES</a></li>
                  <li><a href="#info" onClick={toggleMenu}>ABOUT US</a></li>
                  <li><a href="#gallery" onClick={toggleMenu}>GALLERY</a></li>	
                  <li><a href="#contact" onClick={toggleMenu}>CONTACTS</a></li>									
                </ul>
              </nav>
              
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
