import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Contacts from '../assets/contactIMG.webp'
import Contacts1 from '../assets/contactI.webp'
import Popup from './popup';



const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    setShowPopup(true);
    setTimeout( function() {
      e.target.reset()
    }, 2000);
    e.preventDefault();

    emailjs.sendForm('contact_services', 'contact_forms', form.current, 'CWbji1M-ay1-hHO6f')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
 

  const handleSubmit = (event) => {
    // event.preventDefault();
    setShowPopup(true);
    event.target.reset();
  };
  return (
    <section className="contact">
      <div className="container">
        <div className="contact__title">
          <h1 className="contact__h">CONTACTS</h1>
          <p className="contact__p">If you have specific questions or need any additional information, please contact us</p>
        </div>
        <div className="contact__container">
          <img className="contact__imges" src={Contacts1} alt="" />
          <div className="contact__us">
            <div className="container">
            <a name='contact'></a>
              <h1 className="contact__us__title">CONTACT US</h1>
              <form id="contact-forms" ref={form} onSubmit={sendEmail}>
                <input type="text" id="name" name="user_name" placeholder="Name" required /><br />
                <input type="number" id="phone" name="user_phone" placeholder="Phone" required /><br />
                <input type="email" id="email" name="user_email" placeholder="Email" required /><br />
                <button className="btn__f" type="submit" onClick={handleSubmit}>BOOK</button>
              </form>
              <Popup showPopup={showPopup} setShowPopup={setShowPopup} />
              <img className="contact__img" src={Contacts} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
