import React from 'react';
import Galery1 from '../assets/galery1.webp'
import Galery2 from '../assets/galery2.webp'
import Galery3 from '../assets/galery3.webp'
import Galery4 from '../assets/galery4.webp'



const Gallery = () => {
  return (
    <section className="gallery">
      <div className="container">
        <h1 className="gallery__h">GALLERY</h1>
        <div className="gallery__pictures">
          <div className="gallery__block">
            <img className="gallery__item1 g__i" src={Galery1} alt="" />
            <img className="gallery__item2 g__i" src={Galery2} alt="" />
          </div>
          <div className="gallery__block">
            <img className="gallery__item3 g__i" src={Galery3} alt="" />
            <img className="gallery__item4 g__i" src={Galery4} alt="" />
          </div>
        </div>
        <div className="gallery__pictures1">
          <div className="gallery__p">
            <img className="gallery__item11 g__i" src={Galery3} alt="" />
            <p className="g__p">Food is the foundation of our business. It has to be quality, so we only buy premium food and more organic. Once you fall in love with our food, you will call us repeatedly</p>
          </div>
          <a name="gallery"></a>
          <img className="gallery__item22 g__i" src={Galery2} alt="" />
          <img className="gallery__item33 g__i" src={Galery1} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
